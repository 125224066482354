import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Container } from "react-bootstrap"

const About = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "president.svg" }) {
        publicURL
      }
    }
  `)
  const { publicURL } = image.file
  return (
    <section
      id="会社概要"
      style={{ paddingTop: "100px", paddingBottom: "16px" }}
    >
      <h2 className="h2 text-center mb-4">
        会社概要
      </h2>

      <Container>
        <div className="table-responsive">
          <table className="table ">
            <tbody>
              <tr>
                <td>商号</td>
                <td>株式会社AppReply</td>
              </tr>
              <tr>
                <td>設立</td>
                <td>2021年（令和3年）4月1日</td>
              </tr>
              <tr>
                <td>資本金</td>
                <td>300万円（2021年4月1日現在）</td>
              </tr>
              <tr>
                <td className="align-middle">所在地</td>
                <td>
                  〒194-0021東京都町田市中町1-2-5 SHELL MIYAKO V 3F
                  <br />
                  TEL：042-816-2875
                </td>
              </tr>
              <tr>
                <td>代表者</td>
                <td>
                  代表取締役
                  <img
                    src={publicURL}
                    alt="president"
                    style={{
                      height: "1.7rem",
                      marginBottom: `0`,
                      marginLeft: "8px",
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="align-middle">事業内容</td>
                <td>
                  <p style={{ fontWeight: "bold" }}>
                    ＜モバイルアプリケーション事業＞
                  </p>
                  <p>モバイルアプリケーションの企画・製造・運営事業</p>
                  <p style={{ fontWeight: "bold" }}>＜システム開発事業＞</p>
                  <p>各種システム・アプリケーションの受託開発事業</p>
                  <p style={{ fontWeight: "bold" }}>
                    ＜クリエーターサポート事業＞
                  </p>
                  <p>各種クリエーターのサポート事業</p>
                </td>
              </tr>
              <tr>
                <td className="align-middle">取引銀行</td>
                <td>りそな銀行 町田中央支店</td>
              </tr>
              <tr>
                <td className="align-middle">顧問税理士</td>
                <td>税理士法人わかば</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </section>
  )
}
export default About
